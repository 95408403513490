/* ========= Current Location Page ==========*/
.landing-input::placeholder {
  font-size: 19px!important;
}

/* Header area */
.navbar-area {
    background-color: #1E2B6E!important;
}
.page-logo {
    width: 100px;
    margin-left: 150px;
    @media (min-width: 320px) and (max-width: 480px) {
      margin-left: 113px;
    }
}

.header-area {
  @media (min-width: 320px) and (max-width: 480px) {
    padding-top: 95px;
  }
}
.header-area.header-bg-4 {
    background-color: #1e2b6e6e;
    color: #1E2B6E;
    background-position: center;
    background-size: cover;
    height: 815px;
  }
  .header-area.header-bg-4::before {
    background-color: #fff;
    background-image: url(../locationLink/images/bg.jpg);
    background-position: center;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.6;
  }
.locationBtn {
    color: #fff!important;
    text-decoration: underline;
    font-size: 23px!important;
    margin-bottom: 5px;
    background-color: transparent;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 20px;
    }
  }
  .locationBtn:hover {
    color: #fff;
  }
  .custom-landing-form {
    input {
      width: auto!important;
    }
  }