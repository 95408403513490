/* base style */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,400&display=swap');
/* Default Font */
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
@function line-height($font-size, $line-height: 1.5) {
  @return ($font-size * $line-height);
}
/* Example */
//p{@include font-size(18px)}
/* Default transition */
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
/* Default Border Radius */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
/* Example */
//p{@include border-radius(3)}
/* Default Opacity */
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

/* Default Box Shadow */
@mixin box-shadow($boxShadow) {
  -webkit-box-shadow: $boxShadow; // iOS Safari 3.2 - 4.3, Android 2.1+
  box-shadow: $boxShadow;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Default Colors */
$colorScheme: (
  primary-1: (
    base: #666FE8,
    set: #666FE8
  ),
  primary-2: (
    base: #2e85d7
  ),
  primary-3: (
    base: #666FE8
  ),
  primary-4: (
    base: #272727
  ),
  primary-5: (
    base: #495867
  ),
  primary-6: (
    base: #c9d1d8
  ),
  primary-7: (
    base: #ffffff
  )
);
@function setColor($scheme, $tone: base) {
  @return map-get(map-get($colorScheme, $scheme), $tone);
}
/*Example*/
//p{color:setColor(primary-1,set)}
/* Default Fonts */
$buttons: (
  primary: (
    setColor(primary-1),
    #272727
  ),
  secondary: (
    #c9d1d8,
    #272727
  )
);
.button {
  background-color: #fff;
  color: #fff;
  @include transition(all 0.3s ease-in);
  @each $name, $colors in $buttons {
    $bgColor: nth($colors, 1);
    $fontColor: nth($colors, 2);

    &--#{name} {
      background-color: $bgColor;
      color: $fontColor;
    }
  }
}

body {
  font-family: "Roboto", sans-serif  !important;
}

/*FONTS*/
.roboto-slab {
  font-family: "Roboto", sans-serif !important;
}

.Roboto {
  font-family: "Roboto", sans-serif  !important;
}

/*BUTTONS*/
.btn-pill {
  border-radius: 40px;
}
.btn-width {
  width: 140px;
}
.btn-shadow {
  box-shadow: 2px 2px 9px -3px black;
}
.btn-custom-padding {
  padding: 5px 20px;
}
.btn-light-grey {
  background: #c9d1d8 !important;
  padding: 0.5rem 1rem;
  &:hover {
    background: #a7abaf !important;
    cursor: pointer;
  }
}

/*COLORS*/
.tl-black {
  color: #272727 !important;
}

/*BACKGROUND COLORS*/
.bg-light-grey {
  background: #c9d1d8 !important;
}

.bg-warning {
  background: #fed766 !important;
}
.bg-normal {
  background: #005daa !important;
  &:hover {
    color: #fff;
  }
}

.bttn-success {
  background: #666FE8 !important;
  color: #fff !important;
  padding: 0.5rem 1rem;
  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background: #666FE8 !important;
  }
}

.bg-success {
  background: #666FE8 !important;
}

html,
body {
  font-size: 14px;
}
h1 {
  font-size: 2.6rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.8rem;
}
.small {
  font-size: 0.75rem;
}
.large {
  font-size: 1.25rem;
}
/*SVG -> Custom Color*/
.kt-svg-icon g [fill] {
  fill: #4477ba !important;
}
.kt-input-icon.kt-input-icon--right .form-control {
  border-radius: 20px;
}
.btn.btn-label-brand {
  color: #4477ba;
}
button.btn.btn-label-brand:hover {
  background-color: #4477ba;
}
.error-content {
  color: red;
}
.error {
  color: red;
}

// Border CUSTOM
.custom-landing-form {
  margin-bottom: 0.5rem !important;
  padding-left: 0;
  input {
    margin-bottom: 0.5rem;
    border: none;
    border-radius: unset;
    border-bottom: 1px solid #bdbfbf;
    background-color: transparent;
    padding-left: 0;
  }
  .form-control:focus {
    border-color: #005daa;
  }
}

.user-form {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #bdbfbf;
  border-radius: unset;
  margin-bottom: 0.5rem !important;
  &:focus {
    border-color: #005daa;
  }
}
.kt-height {
  height: 100vh;
  overflow: auto;
}

.btn {
  padding: 0.5rem 1rem;
}

.kt-header-mobile {
  background: #4477ba;
}

.mobile-view {
  background: #eeee;
  position: fixed;
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif  !important;
  .mobile-unavailable {
    background: white;
    padding: 2.5rem 2rem;
    border-radius: 4px;
    box-shadow: 0px 3px 9px -3px #aeaeae;
    max-width: 370px;
    width: 90%;
    color: #777;
    font-size: 14px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-family: "Roboto", sans-serif  !important;
    a.app-link {
      color: #015083;
      text-decoration: underline;
    }
    a.app-link:hover {
      text-decoration: none;
    }
  }
}
