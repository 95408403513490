/* Menu SCSS */

$color-menu-bg: #FAFAFA;
$color-menu-text: #2E384D;
$color-menu-active-bg: #666FE8;

#kt_aside {
  background-color: $color-menu-bg;
  height: 100vh;
  #kt_aside_brand {
    a {
      font-size: 2rem;
      line-height: 2.6875rem;
      font-weight: bold;
      font-family: "Roboto", sans-serif;
      color: #666FE8;
    }
  }
  a {
    color: $color-menu-text;
  }
  .kt-menu__bold {
    .kt-menu__link-text {
      font-weight: 900;
    }
  }
  .kt-menu__color {
    color: $color-menu-text;
  }
  .active {
    background: $color-menu-active-bg;
    color: $color-menu-bg;
    .kt-menu__link-text {
      font-weight: 900;
    }
  }
  .kt-menu__submenu {
    display: block !important;
    .kt-menu__item :hover{
      & {
        background-color: #878FF9;
        color: $color-menu-bg;
      }
    }
  }
}
#kt_wrapper {
  .kt-header__topbar-username {
    font-weight: bold;
    color: $color-menu-text;
    font-size: 1.4rem;
    padding-right: 16px;
  }
}

.top-menu-separator {
  border-bottom: 1px solid #c9d1d8;
}

.top-menu-custom-button {
  border: 0;
  padding: 0;
  background: transparent;
  width: 100%;
  text-align: left;
}
