/* ========= Search Result Page ==========*/
.search-result-wrapper {
  .keyword-container {
    background-color: #4477ba;
    padding: 20px 25px;
    margin: 40px 20px 12px;
    border-radius: 15px;
    @media (min-width: 320px) and (max-width: 480px) {
      margin: 0;
      border-radius: 0;
    }
    .title h3 {
      color: #fff;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 2rem;
    }
    .available-zips {
      border-radius: 0;
      margin: 20px -25px;
      margin-top: 20px;
      padding: 20px;
      .available-zip-title {
        color: #272727;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
      }
      .show-zips {
        //height: 220px;
        max-height: 220px;
        overflow: hidden;
        overflow-y: inherit;
      }
      .current-address {
        margin-top: 20px;
        label {
          color: #272727;
          text-align: left;
          font-size: 13.6px;
          font-weight: 700;
        }
      }
    }
    #search-form .searchBtn {
      margin-right: 34px;
      position: relative !important;
      right: 0 !important;
      top: -70px;
      float: right;
    }
    .current-address .btn {
      font-size: 15px;
    }
  }
  .footerLogo {
    position: relative;
    bottom: 0;
    left: 22px;
    justify-content: left;
    @media (min-width: 320px) and (max-width: 480px) {
      display: none;
    }
    img {
      width: 10%;
    }
  }
  .social-icon {
    position: relative;
    bottom: 77px;
    left: 114px;
  }

  #search-form {
    .btn {
      font-size: 15px;
    }
    button[type="submit"] {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 5px 20px;
      @media (max-width: 480px) {
        position: relative;
        margin-top: 10px;
        bottom: 0;
      }
    }
  }

  .Keyword-label div {
    font-size: 13.6px;
  }

  .location-search-input {
    font-size: 16px !important;
  }

  #distance {
    font-size: 16px !important;
  }

  .btn-zipcode {
    margin-right: 8px;
    font-size: 13px;
    margin-bottom: 8px;
    width: 65px;
    padding: 6px 0 4px 0;
    &:hover {
      background-color: #666FE8 !important;
      color: white !important;
    }
    &:focus {
      background-color: #666FE8 !important;
      color: white !important;
    }
    &:active {
      background-color: #666FE8 !important;
      color: white !important;
    }
  }

  .Shortcode-label {
    div[class$="singleValue"] {
      text-transform: capitalize;
      font-weight: bold;
    }
    div[class$="menu"] {
      text-transform: capitalize;
      font-weight: bold;
    }
  }
}
