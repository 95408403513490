body {
  color: #272727;
}

.headline {
  padding: 0;
}

.sub-headline {
  text-transform: uppercase;
  margin-bottom: 20px;
}
.custom-landing-form {
  margin-bottom: 0;
  border-bottom: 1px solid #bdbfbf;
  .form-control {
    border: none;
    background-color: transparent;
  }
  label {
    color: black;
    font-weight: 600;
  }
}
.profile-container {
  background: #fff;
  width: 100%;
  margin: 20px 40px;
  border-radius: 15px;
  content: "";
  -webkit-box-shadow: 0 2px 20px -15px #000;
  -moz-box-shadow: 0 2px 20px -15px #000;
  box-shadow: 0 2px 20px -15px #000;
  -moz-border-radius: 100px / 10px;

  .little-description {
    padding: 2.5rem;
  }
}

.nav {
  border-right: 1px solid #e9e9e9;
  a {
    padding-left: 40px;
    border-radius: 0 !important;
    color: black;
  }

  a:last-child {
    margin-bottom: 25px !important;
  }

  .active {
    background: #666FE8  !important;
    color: white !important;
  }

  .menu-container {
    margin-bottom: 25px;
  }
}

.profile-container .nav .nav-link {
  padding: 0.8rem 2.5rem;
  font-weight: 600;
}

.profile-container .tab-content {
  padding: 2.5rem;
  .custom-landing-form {
    input[type="text"],
    input[type="email"],
    input[type="password"] {
      padding: 0;
      height: 30px;
    }
  }
}
.nav-pills {
  margin: 0;
}

.change-password-switcher label {
  color: black;
  font-weight: 600;
  font-size: 1rem !important;
}

.password-panel.show {
  display: block;
}

.password-panel.hide {
  display: none;
}

.payment-form-input {
  margin-bottom: 2rem;
}

.error-invalid {
  color:red;
}

.file-input {
  height: initial;
  padding: 0;
}

.remove-phone-number {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-information {
  margin-top: 10px;
}

span.active {
  color: green;
  font-weight: bold;
}

span.pending {
  color: brown;
  font-weight: bold;
}