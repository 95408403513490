::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* SCSS List */
.search-input {
  border: 1px solid #4958677d !important;
  border-radius: 28px;
  height: 32px;
  // margin-top: 9px;
  float: right;
  &::placeholder {
    padding-left: 10px;
  }
  &:focus {
    color: #272727;
    padding-left: 10px;
    outline: none;
  }
}

.search {
  position: relative;
  float: right;
  right: 35px;
  top: 4px;
}
.archive-btn {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}
/* ========= Messaging dashboard ==========*/
.kt-chat {
  .kt-portlet {
    box-shadow: -1px 0px 20px rgba(39, 39, 39, 0.16);
    border-radius: 4px;
    //max-height: 800px;
    //overflow: hidden;
    //overflow-y: scroll;

    .kt-portlet__body {
      padding: 8px 3px 0 25px;
      background: #fff;
      border-radius: 0;
    }
    .kt-portlet__head {
      background: #4477ba;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      min-height: unset;
      .kt-chat__label {
        color: #fff;
        .kt-chat__title {
          font-size: 1.4rem;
          line-height: 1.6875rem;
          padding-bottom: 10px;
        }
        .kt-chat__status {
          font-size: 1rem;
          line-height: 1.125rem;
        }
        button {
          font-size: 1.25rem;
        }
      }
      .kt-chat__head {
        width: 100%;
        .kt-chat__title,
        .kt-chat__status {
          color: #fff;
          text-align: left;
        }
        .kt-chat__label {
          padding: 20px 0;
        }
      }
    }
  }
  .kt-chat__messages {
    .kt-chat__message.kt-chat__outbound {
      border-radius: 12px 15px 0 15px;
      background: rgba(46, 133, 215, 0.32);
      font-size: 1rem;
      line-height: 1.25rem;
      margin-left: 50%;
      text-align: left;
      .kt-chat__datetime {
        color: #4477ba !important;
        font-weight: 600 !important;
      }
      .kt-chat__user {
        padding: 15px 0 0 15px;
      }
      .kt-chat__text {
        color: #272727;
        text-align: left;
        padding: 0 0 15px 15px;
        max-width: 260px;
        overflow-wrap: break-word;
      }
    }
    .kt-chat__message.kt-chat__message--inbound {
      border-radius: 15px 12px 15px 0;
      background: rgba(201, 209, 216, 0.5);
      font-size: 1rem;
      line-height: 1.25rem;
      margin-right: 50%;
      .kt-chat__datetime {
        color: #4477ba;
        font-weight: 600;
      }
      .kt-chat__user {
        padding: 15px 0 0 15px;
      }
      .kt-chat__text {
        color: #272727;
        text-align: left;
        padding: 0 0 15px 15px;
        max-width: 260px;
        overflow-wrap: break-word;
      }
    }
  }
  .kt-portlet__foot {
    background: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
.kt-messaging {
  .kt-input-icon {
    .form-control:focus {
      border-color: #4477ba;
    }
  }
}

.message-editor {
  display: flex;
  .add-file {
    flex-grow: 0;
    .file-upload-label {
      padding: 8px;
      padding-left: 0;
      &:hover .kt-svg-icon g [fill],
      &:focus .kt-svg-icon g [fill],
      &:active .kt-svg-icon g [fill] {
        fill: #063359 !important;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  #txt-messaging {
    font-size: 1.1rem;
    flex-grow: 1;
    height: 42px !important;
    border-radius: 21px;
    padding-left: 16px;
    background-color: #efefef;
    border: none;
    outline-width: 0;
  }
  .action-wrappers {
    flex-grow: 0;
    flex-basis: 85px;
    button {
      &:hover .kt-svg-icon g [fill],
      &:focus .kt-svg-icon g [fill],
      &:active .kt-svg-icon g [fill] {
        fill: #063359 !important;
      }
      &.kt-chat__reply {
        padding: 8px 0px;
      }
    }
  }
  .toggleEmoji {
    // position: absolute;
    right: 0;
    top: 0;
  }
  .emojipicker {
    // position: absolute;
    right: 0;
    top: -415px;
  }
}

.dropdown-emoji-button {
  padding: 0;
  margin: 0;
  // position: absolute;
  right: 0;
  top: 0;
  .emoji-toggler {
    background: transparent;
    border-color: transparent;
    box-shadow: none !important;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border-color: transparent;
      box-shadow: none !important;
    }
  }
}

.activeConversation {
  background: rgba(0, 0, 0, 0.05) !important;
}

.kt-chat .kt-chat__input .kt-chat__toolbar {
  margin-bottom: 1rem;
  margin-top: 0;
}

.messaging-container {
  position: relative;
  .messaging-container-tools {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    .kt-subheader__search {
      display: inline-block;
      width: 200px;
    }
  }
}

.kt-app__aside {
  @media (max-width: 1024px) {
    position: unset;
    width: 100% !important;
    background-color: transparent !important;
  }
}
