.packages-wrapper {
  .kt-item {
    background: #fff;
    border-radius: 20px;
    padding: 25px;
    margin-bottom: 20px;
    box-shadow: 0 1px 20px rgba(39, 39, 39, 0.16);
    .title {
      color: rgba(46, 133, 215, 1);
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 700;
      font-family: Roboto, sans-serif;
    }
    .desc_primary {
      color: rgba(39, 39, 39, 1);
      font-size: 2rem;
      line-height: 2.25rem;
      font-weight: 900;
      text-transform: uppercase;
    }
    .desc-title {
      font-weight: 500;
      font-size: 1rem;
    }
    .desc_secondary {
      color: #272727;
      font-weight: 700;
      font-size: 1rem;
    }
    .btn {
      width: 100%;
      margin-bottom: 15px;
      font-weight: 500;
    }
    .detail_group {
      margin-bottom: 10px;
    }
  }
  .kt-map > div {
    height: 100% !important;
  }
  .user-table-container {
    padding: 0 !important;
    .ReactTable {
      .-headerGroups {
        background: #4477ba;
        color: #fff;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        font-size: 1rem;
      }
      .rt-table {
        .rt-thead {
          .rt-tr {
            .rt-th {
              .rt-resizable-header-content {
                margin: 14px;
              }
            }
          }
        }
        .-headerGroups {
          .rt-tr {
            .rt-th:nth-of-type(2) {
              flex: 200 0 auto !important;
              width: 200px !important;
            }
            .rt-th:nth-of-type(1) {
              display: none;
            }
          }
        }
      }
    }
    .rt-tbody .rt-td:first-child {
      text-align: left !important;
    }
  }
  .desc_half {
    width: 50%;
  }
  .desc_price {
    background: rgba(255, 255, 255, 1);
    border: 1px solid #c9d1d8;
    width: 80px;
    padding: 0 0 0 4px;
    text-align: right;
  }
  .desc_required {
    color: red;
    font-weight: 700;
  }
  .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-right: 0;
  }

  .add-btn {
    .kt-svg-icon g [fill] {
      fill: white !important;
    }
  }

  .btn-normal {
    background: #4477ba;
    color: white;
    &:hover,
    &:active,
    &:focus {
      background: #376097;
      color: white;
    }
    &.disabled,
    &:disabled {
      opacity: 0.5;
      &:hover,
      &:active,
      &:focus {
        background: #4477ba;
        color: white;
      }
    }
  }

  .requests-header {
    position: relative;
    .requests-header-tools {
      position: absolute;
      top: 0;
      right: 0;
      float: right;
      .kt-subheader__search {
        display: inline-block;
        width: 200px;
      }
    }
  }
  .zipcode-tag-wrapper {
    max-height: 220px;
    min-height: 100px;
    overflow-y: auto;
    .zipcode-tag {
      margin-right: 8px;
      font-size: 13px;
      margin-bottom: 8px;
      width: 65px;
      padding: 5px 10px 4px 10px;
      background: #aed6a8;
      border-radius: 30px;
      display: inline-block;
      text-align: center;
    }
  }

  .error-content {
    font-size: 12px;
  }

  .req-btn {
    width: 150px !important;
  }

  .request-list-tools-business {
    display: inline-block;
    width: 200px;
    margin-right: 15px;
  }

  .ant-btn {
    .anticon {
      vertical-align: 0;
      line-height: 0;
    }
  }
  .detail_group {
    .title {
      margin-bottom: 0;
      .desc-title {
        width: 100px;
        display: inline-block;
        text-align: right;
        padding-right: 0.5rem;
      }
    }
  }

  .selection {
    display: inline-block;
    width: 300px;
    margin-left: 0.5rem;
  }

  .address-input {
    display: inline-block;
    width: 300px;
    margin-left: 0.5rem;
    .search-container {
      padding-bottom: 0rem !important;
      .location-search-input {
        height: 38px;
        border-radius: 5px;
        outline: none;
        box-shadow: none;
        border: 1px solid #ccc;
        width: 300px;
        padding: 10px;
        font-weight: bold;
      }
      .autocomplete-dropdown-container {
        position: absolute;
        color: black;
        z-index: 5;
        padding: 5px 0;
        background: #ddd;
        border-radius: 5px;
        .suggestion-item {
          padding: 8px 12px;
          margin: 0 8px;
          cursor: pointer;
        }
        .suggestion-item--active {
          padding: 8px 12px;
          margin: 0 8px;
          background-color: white;
          cursor: pointer;
          border-radius: 20px;
          color: black;
        }
      }
    }
  }

  .distance-input {
    display: inline-block;
    margin-left: 0.5rem;
    input {
      display: inline-block;
      color: black;
      padding-left: 10px;
      width: 75px;
      height: 38px;
      border: 1px solid #ccc;
      font-weight: bold;
      border-radius: 5px;
    }
  }

  .nationwide-checkbox {
    display: inline-flex;
    margin-left: 0.75rem;
    align-items: center;
    height: 38px;
    .bp3-checkbox {
      margin-bottom: 0;
    }
  }

  .price-input {
    display: inline-flex;
    margin-left: 0.5rem;
    .input-group-addon {
      height: 38px;
      border-radius: 5px 0 0 5px;
      align-items: center;
      display: inline-flex;
    }
    #price {
      display: inline-block;
      color: black;
      padding-left: 10px;
      width: 75px;
      height: 38px;
      border: 1px solid #ccc;
      font-weight: bold;
      border-radius: 0 5px 5px 0;
    }
  }

  .error-msg {
    margin-left: 15px;
  }

  .map-section {
    > div {
      height: 700px !important;
    }
  }

  .zip-list {
    display: block;
    max-height: 220px;
    overflow-y: auto;
    margin-top: 10px;
    margin-left: 2rem;
    .zipcode-item {
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 2rem;
      padding: 7px 12px 6px;
      width: 70px;
      text-align: center;
      margin-right: 5px;
      margin-bottom: 8px;
    }
  }

  .search-btn {
    margin-left: 2.2rem;
    width: 170px !important;
    margin-bottom: 0 !important;
  }
  .MuiDialog-container {
    .MuiDialogTitle-root {
      .MuiTypography-h6 {
        font-size: 1.5rem;
        font-family: "Roboto", sans-serif  !important;
        color: #272727;
        line-height: 1.75rem;
      }
    }
    .MuiDialogContent-root {
      padding: 30px;
      .MuiTypography-body1 {
        font-size: 1rem;
        font-family: "Roboto", sans-serif  !important;
        color: #272727;
        line-height: 1.25rem;
      }
      label {
        font-size: 1rem;
        font-family: "Roboto", sans-serif  !important;
        color: #272727;
        line-height: 1.25rem;
      }
      .kt-dialog__space {
        padding-top: 10px;
      }
    }
  }
}
