.kt-portlet {
  background-color: unset;
  .kt-portlet__body {
    padding: 0;
  }
}
.ReactTable.-highlight .rt-tbody .rt-tr:hover {
  background: rgba(0, 0, 0, 0.05) !important;
  cursor: pointer;
}
$table-border-radius: 4px;
.ReactTable {
  border: none !important;
  color: #272727;
  //max-height: 800px;
  box-shadow: 0 1px 16px -15px #6b6b6b;
  border-radius: $table-border-radius;
  -moz-border-radius: $table-border-radius;
  -webkit-border-radius: $table-border-radius;
  .-header {
    border-top-left-radius: $table-border-radius;
    border-top-right-radius: $table-border-radius;
  }
  .-pagination {
    border-bottom-right-radius: $table-border-radius;
    border-bottom-left-radius: $table-border-radius;
    box-shadow: none;
    .-previous,
    .-next {
      .-btn {
        border-bottom-left-radius: $table-border-radius;
        border-bottom-right-radius: $table-border-radius;
      }
    }
  }
  .rt-tbody {
    padding: 0 30px;
    background: #fff;
    .rt-td {
      border-right: unset;
      padding: 15px 0;
      text-align: center;
      line-height: 20px;
      .btn {
        padding: 0 5px 0 0;
      }
      .kt-svg-icon {
        height: 20px;
        width: 20px;
      }
      .kt-switch input:empty {
        position: relative;
      }
      .kt-switch.kt-switch--sm input:empty ~ span {
        height: 16px;
      }
      .kt-switch.kt-switch--sm input:empty ~ span:after {
        height: 12px;
        width: 12px;
      }
      .kt-switch.kt-switch--sm input:empty ~ span:before {
        width: 32px;
      }
    }
    .rt-tr {
      background: #fff;
    }
    .rt-tr.-odd,
    .rt-tr .-even {
      background: #fff !important;
    }
    .rt-tr-group {
      border-bottom: 1px solid #c9d1d8;
    }
  }
  .rt-table {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 0.9375rem;
    .rt-thead {
      .rt-tr {
        margin: 0 30px;
      }
      .rt-th,
      .rt-td {
        padding: 15px 0;
      }
    }
    .rt-thead.-header {
      box-shadow: none !important;
      background-color: rgba(201, 209, 216, 0.5);
      color: #272727;
      font-weight: bold;
    }
  }
}
